body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@page {
  size: A4 portait;
  margin: 20px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.thi-report-button {
  margin-top: 15px;
}
.thi-report-wrapper {
  width: 400px;
  margin: 15px auto;
}

.nopadding {
  padding: 0 !important;
  margin: 0 !important;
}

.pcr-font-14 {
  font-size: 14px;
}

.t-justify {
  text-align: justify;
}

td.td-name {
  width: 30%;
}
td.results {
  width: 10%;
}
td.others {
  width: 19%;
}
